<template>
  <div class="products category-leaflet">
    <Breadcrumb :items="breadcrumbs" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container class="py-0">
      <CategoryTitle
        :category="category"
        :selectedCategory="selectedCategory"
        :count="count"
        :key="category.categoryId"
      />
    </v-container>

    <v-container>
      <div v-if="!categoryId">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide
            v-for="subCategory in category.children"
            :key="subCategory.categoryId"
          >
            <v-card
              class="category-card"
              outlined
              height="100%"
              :to="{
                name: 'Category',
                params: {
                  pathMatch: category.slug
                },
                query: { categoryId: subCategory.categoryId }
              }"
            >
              <img
                :src="$ebsn.meta(subCategory, 'category_info.THUMB')"
                :alt="subCategory.name"
                class="category-card-img mt-3 mx-3"
              />
              <v-card-title class="category-card-title primary--text">{{
                subCategory.name
              }}</v-card-title>
            </v-card>
          </swiper-slide>
        </swiper>
        <div id="pagination-leaflet-slider" class="swiper-pagination"></div>

        <h3>Tutti i prodotti</h3>
        <ProductListGrid
          class="mt-5"
          :fluid="$vuetify.breakpoint.xsOnly"
          :parentCategoryId="category.categoryId"
          :key="category.categoryId"
          hideFilterString="Categorie,Main Term"
          @productsCount="updateCount"
        />
      </div>
      <ProductListGrid
        v-else
        :fluid="$vuetify.breakpoint.xsOnly"
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="category.categoryId"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
      />
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.category-leaflet {
  .v-card.category-card {
    width: 220px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .category-card-img {
      height: 70px;
      max-width: 100%;
    }
    .category-card-title {
      font-size: 1.2rem;
      line-height: 1;
      min-height: 75px;
      align-items: flex-start;
      text-align: center;
      word-break: keep-all;
      padding-left: 0px;
      padding-right: 0px;
      font-weight: bold;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";

export default {
  name: "CategoryLeaflet",
  mixins: [categoryMixins],
  data() {
    return {
      categoryId: null,
      count: null,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 10,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: "#pagination-leaflet-slider",
          clickable: true
        },
        breakpoints: {
          0: {
            slidesPerView: 2
          },
          600: {
            slidesPerView: 4
          },
          960: {
            slidesPerView: 5
          },
          1264: {
            slidesPerView: 7
          },
          1904: {
            slidesPerView: 9
          }
        }
      }
    };
  },
  components: {
    CategoryTitle,
    ProductListGrid,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
